<template>
  <section class="container pb-6">
    <h1 class="title is-3">Results</h1>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>User</th>
          <th>Questions set</th>
          <th>Title</th>
          <th>Date</th>
          <th>Started/Completed at</th>
          <th>Duration</th>
          <th>Completion</th>
          <th>Score</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="exam in exams"
          :key="
            'exam_' + exam.user_id + '_' + exam.set_id + '_' + exam.start_date
          "
        >
          <td>{{ exam.user.email }}</td>
          <td>{{ exam.set.title }}</td>
          <td>{{ exam.title }}</td>
          <td>
            {{ moment(exam.start_date).format("YYYY-MM-DD") }}
            <br />
            {{ moment(exam.due_date).format("YYYY-MM-DD") }}
          </td>
          <td>{{ exam.started_at }}<br />{{ exam.completed_at }}</td>
          <td>
            <span v-if="exam.completed_at">
              {{
                moment
                  .utc(moment(exam.completed_at).diff(exam.started_at))
                  .format("H[h] m[m] s[s]")
              }}
            </span>
          </td>
          <td>
            <span v-if="exam.started_at">
              {{
                Math.round((exam.answers_count / exam.questions_count) * 100)
              }}%
            </span>
          </td>
          <td>
            <b-tag type="is-warning" v-if="exam.completed_at && !exam.reviewed">
              To review
            </b-tag>
            <b-tag type="is-default" v-else-if="!exam.started_at">
              Pending
            </b-tag>
            <span v-else> {{ Math.round(exam.score * 10, 2) }} / 10 </span>
          </td>
          <td>
            <b-button
              type="is-info"
              size="is-small"
              tag="router-link"
              :to="`/result/${exam.exam_id}`"
              >Review</b-button
            >
          </td>
        </tr>
      </tbody>
    </table>
    <div class="box">
      <b-field label="E-Mail">
        <b-input
          type="email"
          maxlength="100"
          placeholder="Mail of the candidate..."
          v-model.trim="exam.mail"
        />
      </b-field>

      <b-field label="Question set">
        <b-select v-model="exam.set_id">
          <option
            v-for="qs in sets"
            :key="'qs_' + qs.set_id"
            :value="qs.set_id"
          >
            {{ qs.title }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Title">
        <b-input type="text" v-model.trim="exam.title" />
      </b-field>

      <b-field label="Start date">
        <b-input type="date" v-model.trim="exam.start_date" />
      </b-field>

      <b-field label="Due date">
        <b-input type="date" v-model.trim="exam.due_date" />
      </b-field>

      <b-button
        type="is-primary"
        :class="{ 'is-loading': loading }"
        :disabled="loading"
        @click="createExam"
      >
        Create
      </b-button>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ResultsPage",
  data() {
    return {
      moment: moment,
      loading: false,
      error: null,
      sets: [],
      exams: [],
      exam: {},
    };
  },
  mounted() {
    this.fetchExams();
    this.fetchSets();
  },
  methods: {
    fetchExams() {
      axios.get("/admin/exams").then((response) => {
        this.exams = response.data.exams;
      });
    },
    fetchSets() {
      axios.get("/admin/sets").then((response) => {
        this.sets = response.data.sets;
      });
    },
    createExam() {
      this.loading = true;
      this.error = null;
      axios
        .post("/admin/exam", {
          ...this.exam,
        })
        .then(() => {
          this.exam = {};
          this.fetchExams();
        })
        .catch((error) => {
          this.error = error.response.data.message || "An error occurred!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
