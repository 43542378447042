<template>
  <section class="container pb-6">
    <h1 class="title is-3">Review</h1>
    <b-breadcrumb align="is-left">
      <b-breadcrumb-item tag="router-link" to="/">Home</b-breadcrumb-item>
      <b-breadcrumb-item tag="router-link" to="/results"
        >Results</b-breadcrumb-item
      >
      <b-breadcrumb-item tag="router-link" :to="`/result/${this.id}`" active
        >Review</b-breadcrumb-item
      >
    </b-breadcrumb>

    <div>
      <b-field label="User">{{ exam.user_id }}</b-field>
      <b-field label="Questions set">{{ exam.set_id }}</b-field>
      <b-field label="Started at">{{ exam.started_at }}</b-field>
      <b-field label="Completed at">{{ exam.completed_at }}</b-field>
    </div>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>Question</th>
          <th>Duration</th>
          <th>Time</th>
          <th>User Answer</th>
          <th>Correct Answer</th>
          <th>Evaluation</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="question in questions"
          :key="'question_' + question.question_id"
        >
          <td>{{ question.content }}</td>
          <td>{{ question.duration }}</td>
          <td>
            {{ question.displayed_at }}
            <br />
            <span v-if="question.answered_at">
              {{
                moment
                  .utc(moment(question.answered_at).diff(question.displayed_at))
                  .format("m[m] s[s]")
              }}
            </span>
            <b-tag v-else type="is-warning">Not answered</b-tag>

            <b-tag v-if="!question.displayed_at" type="is-danger">
              Not displayed
            </b-tag>
          </td>
          <td>
            <pre class="p-1">{{ question.user_answer }}</pre>
          </td>
          <td>
            <pre class="p-1">{{ question.correct_answer }}</pre>
          </td>
          <td>
            <b-field>
              <p class="control">
                <b-button
                  :class="{
                    'is-danger': question.evaluation == '0',
                    'is-loading': loading[question.question_id],
                  }"
                  size="is-small"
                  @click="evaluation(question, 0)"
                >
                  Wrong
                </b-button>
              </p>
              <p class="control">
                <b-button
                  :class="{
                    'is-warning': question.evaluation == '5',
                    'is-loading': loading[question.question_id],
                  }"
                  size="is-small"
                  @click="evaluation(question, 5)"
                >
                  Partially
                </b-button>
              </p>
              <p class="control">
                <b-button
                  :class="{
                    'is-success': question.evaluation == '10',
                    'is-loading': loading[question.question_id],
                  }"
                  size="is-small"
                  @click="evaluation(question, 10)"
                >
                  Correct
                </b-button>
              </p>
            </b-field>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ReviewPage",
  props: {
    id: {},
  },
  data() {
    return {
      moment: moment,
      exam: {},
      questions: [],
      loading: {},
    };
  },
  mounted() {
    this.fetchQuestions();
  },
  methods: {
    fetchQuestions() {
      axios.get(`/admin/exam/${this.id}`).then((response) => {
        this.exam = response.data.exam;
        this.questions = response.data.questions;
      });
    },
    evaluation(question, evaluation) {
      this.loading[question.question_id] = true;
      axios
        .post(`/admin/exam/${this.id}/question/${question.question_id}`, {
          question_id: question.question_id,
          evaluation,
        })
        .then(() => {
          this.fetchQuestions();
        })
        .finally(() => {
          this.loading[question.question_id] = false;
        });
    },
  },
};
</script>

<style scoped>
pre {
  max-width: 380px;
}
</style>
