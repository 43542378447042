<template>
  <section class="container pb-6" v-if="set">
    <h1 class="title is-3">{{ set.title }}</h1>

    <b-breadcrumb align="is-left">
      <b-breadcrumb-item tag="router-link" to="/">Home</b-breadcrumb-item>
      <b-breadcrumb-item tag="router-link" to="/sets">Sets</b-breadcrumb-item>
      <b-breadcrumb-item tag="router-link" :to="`/set/${this.id}`" active
        >Set</b-breadcrumb-item
      >
    </b-breadcrumb>

    Estimated time <b>{{ Math.round(estimatedTime / 60) }}</b> minutes.<br />
    Total of <b>{{ totalQuestions }}</b> questions.

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>#</th>
          <th>Category</th>
          <th>Level</th>
          <th>Question</th>
          <th>Duration</th>
          <th>Selected</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(question, index) in questions"
          :key="'question_' + question.question_id"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ question.catalog_id }}</td>
          <td>{{ question.level_id }}</td>
          <td>{{ question.content }}</td>
          <td>{{ question.duration }}</td>
          <td>
            <b-field>
              <b-switch
                type="is-success"
                :value="selected[question.question_id] === true"
                :disabled="loading[question.question_id] === true"
                @input="toggle(question.question_id)"
              ></b-switch>
            </b-field>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "SetPage",
  props: {
    id: {},
  },
  data() {
    return {
      moment: moment,
      loading: {},
      set: {},
      questions: [],
      selected: {},
    };
  },
  computed: {
    estimatedTime: function () {
      const $this = this;
      return $this.questions.reduce((acc, val) => {
        if (val.question_id && $this.selected[val.question_id] === true) {
          return acc + parseInt(val.duration) + 5;
        }
        return acc;
      }, 0);
    },
    totalQuestions: function () {
      return Object.values(this.selected).filter((s) => s === true).length;
    },
  },
  mounted() {
    this.fetchQuestionSets();
  },
  methods: {
    fetchQuestionSets() {
      axios.get(`/admin/set/${this.id}`).then((response) => {
        this.set = response.data.set;
        this.questions = response.data.questions;
        this.selected = response.data.selected;
      });
    },
    toggle(question_id) {
      this.$set(this.loading, question_id, true);
      const newSelected = !this.selected[question_id] === true;
      axios
        .post(`/admin/set/${this.id}/question/${question_id}`, {
          selected: newSelected,
        })
        .then(() => {
          this.$set(this.selected, question_id, newSelected);
        })
        .finally(() => {
          this.$set(this.loading, question_id, false);
        });
    },
  },
};
</script>
