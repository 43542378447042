<template>
  <section class="container pb-6">
    <h1 class="title is-3">Questions</h1>

    <CatalogsNode
      v-for="catalog in tree"
      :key="'catalog_' + catalog.catalog_id"
      :catalog="catalog"
      :levels="levels"
      @refresh="fetchQuestions"
    />
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "QuestionPage",
  data() {
    return {
      loading: false,
      tree: [],
      levels: [],
    };
  },
  mounted() {
    this.fetchQuestions();
  },
  methods: {
    fetchQuestions() {
      axios
        .get("/admin/questions")
        .then((response) => {
          this.tree = response.data.tree;
          this.levels = response.data.levels;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
