<template>
  <section class="container pb-6">
    <h1 class="title is-3">Sets</h1>

    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(set, index) in sets" :key="'set_' + set.set_id">
          <td>{{ index + 1 }}</td>
          <td>{{ set.title }}</td>
          <td>
            <b-button
              type="is-info"
              size="is-small"
              tag="router-link"
              :to="`/set/${set.set_id}`"
            >
              Edit
            </b-button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="box">
      <b-field label="Title">
        <b-input
          type="text"
          maxlength="255"
          placeholder="Topic of the set"
          v-model.trim="set.title"
        />
      </b-field>
      <b-button
        type="is-primary"
        :class="{ 'is-loading': loading }"
        :disabled="loading"
        @click="createSet"
      >
        Create
      </b-button>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SetsPage",
  data() {
    return {
      loading: false,
      sets: [],
      set: {},
    };
  },
  mounted() {
    this.fetchQuestionSets();
  },
  methods: {
    fetchQuestionSets() {
      axios.get("/admin/sets").then((response) => {
        this.sets = response.data.sets;
      });
    },
    createSet() {
      this.loading = true;
      axios
        .post("/admin/set", {
          title: this.set.title,
        })
        .then(() => {
          this.fetchQuestionSets();
          this.set = {};
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
