import axios from "axios";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import CatalogsNode from "./components/CatalogsNode";
import SlideFrame from "./components/SlideFrame";
import "./main.scss";
import ExamPage from "./pages/ExamPage.vue";
import QuestionPage from "./pages/QuestionPage.vue";
import ResultsPage from "./pages/ResultsPage.vue";
import ReviewPage from "./pages/ReviewPage.vue";
import SetPage from "./pages/SetPage.vue";
import QuestionSetsPage from "./pages/SetsPage.vue";
import store from "./store";

Vue.component("SlideFrame", SlideFrame);
Vue.component("CatalogsNode", CatalogsNode);

Vue.use(Buefy);

Vue.config.productionTip = false;

/**
 * Axios configuration
 */
axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL ||
  process.env.apiUrl ||
  "http://localhost:9000/";
axios.defaults.withCredentials = true;

/**
 * Routes
 */
Vue.use(VueRouter);

const routes = [
  { path: "/", component: ExamPage },
  { path: "/questions", component: QuestionPage },
  { path: "/sets", component: QuestionSetsPage },
  { path: "/set/:id", props: true, component: SetPage },
  { path: "/results", component: ResultsPage },
  { path: "/result/:id", props: true, component: ReviewPage },
];

const router = new VueRouter({
  routes, // short for `routes: routes`
});

/**
 * Vue instance
 */
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
