<template>
  <div>
    <SlideFrame v-if="exam" title="What is going to happen?">
      <p class="subtitle is-3">
        We are going to ask a series of <b>{{ exam.questions }}</b> questions,
        the test is going to last
        <b>{{ Math.round(exam.duration / 60) }}</b> minutes.
      </p>
      <p class="subtitle is-3">
        Each questions will have a <b>topic</b> and <b>difficulty</b>.
      </p>
      <p class="subtitle is-3"><b>You will be limited in time to answer!</b></p>
      <p class="subtitle is-3">
        When the time is running out, it will auto-submit your answer, and then
        go to the next question. You will have <b>{{ exam.pause }}</b> seconds
        pause between each question.
      </p>
      <b-message v-if="error" type="is-danger" has-icon>
        {{ error }}
      </b-message>
      <template slot="actions">
        <div class="level">
          <div class="level-left">
            <b-button
              icon-left="arrow-left-bold"
              :class="{ 'is-loading': loading }"
              :disabled="loading"
              @click="prev()"
            >
              Back
            </b-button>
          </div>
          <div class="level-right">
            <b-button
              class="is-primary"
              :class="{ 'is-loading': loading }"
              :disabled="loading"
              @click="next()"
            >
              Start the checkup!
            </b-button>
          </div>
        </div>
      </template>
    </SlideFrame>
    <SlideFrame v-else title="No tests currently available.">
      <template slot="actions">
        <div class="level">
          <div class="level-left">
            <b-button
              icon-left="arrow-left-bold"
              :class="{ 'is-loading': loading }"
              :disabled="loading"
              @click="prev()"
            >
              Back
            </b-button>
          </div>
        </div>
      </template>
    </SlideFrame>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "IntroductionStep",
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({ exam: "getCurrentExam" }),
  },
  methods: {
    next() {
      this.loading = true;
      this.error = null;
      axios
        .post(`/exam/${this.exam.exam_id}/start`)
        .then(() => {
          this.$emit("next");
        })
        .catch((error) => {
          this.error = error.response.data.message || "An error occured!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    prev() {
      this.$emit("prev");
    },
  },
};
</script>
