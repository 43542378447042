<template>
  <div id="app">
    <b-navbar>
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/ag_logo.png" alt="AlienGen" />
        </b-navbar-item>
      </template>

      <template #start>
        <b-navbar-item
          v-if="isAdmin"
          tag="router-link"
          :to="{ path: '/questions' }"
        >
          Questions
        </b-navbar-item>
        <b-navbar-item v-if="isAdmin" tag="router-link" :to="{ path: '/sets' }">
          Sets
        </b-navbar-item>
        <b-navbar-item
          v-if="isAdmin"
          tag="router-link"
          :to="{ path: '/results' }"
        >
          Results
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div v-if="!user" class="buttons">
            <a class="button is-light" :class="{ 'is-loading': loading }">
              Log in
            </a>
          </div>
          <div v-else>
            {{ user.email }}
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <router-view />

    <footer class="copyright">
      Copyright {{ moment().format("YYYY") }} Checkup by
      <a href="https://aliengen.com/#checkup">AlienGen</a>
    </footer>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      loading: false,
      moment: moment,
    };
  },
  computed: {
    ...mapGetters({ user: "getUser", isAdmin: "getAdmin" }),
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$store.dispatch("refreshUser").finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
#app {
  position: relative;
}

.copyright {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
}
</style>
