var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container pb-6"},[_c('h1',{staticClass:"title is-3"},[_vm._v("Review")]),_c('b-breadcrumb',{attrs:{"align":"is-left"}},[_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/"}},[_vm._v("Home")]),_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":"/results"}},[_vm._v("Results")]),_c('b-breadcrumb-item',{attrs:{"tag":"router-link","to":("/result/" + (this.id)),"active":""}},[_vm._v("Review")])],1),_c('div',[_c('b-field',{attrs:{"label":"User"}},[_vm._v(_vm._s(_vm.exam.user_id))]),_c('b-field',{attrs:{"label":"Questions set"}},[_vm._v(_vm._s(_vm.exam.set_id))]),_c('b-field',{attrs:{"label":"Started at"}},[_vm._v(_vm._s(_vm.exam.started_at))]),_c('b-field',{attrs:{"label":"Completed at"}},[_vm._v(_vm._s(_vm.exam.completed_at))])],1),_c('table',{staticClass:"table is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.questions),function(question){return _c('tr',{key:'question_' + question.question_id},[_c('td',[_vm._v(_vm._s(question.content))]),_c('td',[_vm._v(_vm._s(question.duration))]),_c('td',[_vm._v(" "+_vm._s(question.displayed_at)+" "),_c('br'),(question.answered_at)?_c('span',[_vm._v(" "+_vm._s(_vm.moment .utc(_vm.moment(question.answered_at).diff(question.displayed_at)) .format("m[m] s[s]"))+" ")]):_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v("Not answered")]),(!question.displayed_at)?_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v(" Not displayed ")]):_vm._e()],1),_c('td',[_c('pre',{staticClass:"p-1"},[_vm._v(_vm._s(question.user_answer))])]),_c('td',[_c('pre',{staticClass:"p-1"},[_vm._v(_vm._s(question.correct_answer))])]),_c('td',[_c('b-field',[_c('p',{staticClass:"control"},[_c('b-button',{class:{
                  'is-danger': question.evaluation == '0',
                  'is-loading': _vm.loading[question.question_id],
                },attrs:{"size":"is-small"},on:{"click":function($event){return _vm.evaluation(question, 0)}}},[_vm._v(" Wrong ")])],1),_c('p',{staticClass:"control"},[_c('b-button',{class:{
                  'is-warning': question.evaluation == '5',
                  'is-loading': _vm.loading[question.question_id],
                },attrs:{"size":"is-small"},on:{"click":function($event){return _vm.evaluation(question, 5)}}},[_vm._v(" Partially ")])],1),_c('p',{staticClass:"control"},[_c('b-button',{class:{
                  'is-success': question.evaluation == '10',
                  'is-loading': _vm.loading[question.question_id],
                },attrs:{"size":"is-small"},on:{"click":function($event){return _vm.evaluation(question, 10)}}},[_vm._v(" Correct ")])],1)])],1)])}),0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Question")]),_c('th',[_vm._v("Duration")]),_c('th',[_vm._v("Time")]),_c('th',[_vm._v("User Answer")]),_c('th',[_vm._v("Correct Answer")]),_c('th',[_vm._v("Evaluation")])])])}]

export { render, staticRenderFns }