<template>
  <SlideFrame
    title="Enter your mail!"
    @next="next"
    @prev="prev"
    :loading="loading"
  >
    <p class="subtitle is-3">
      This will allow us to get back to you after reviewing your answers.
    </p>
    <section class="box">
      <b-field :type="{ 'is-danger': error }" :message="error">
        <b-input
          maxlength="100"
          placeholder="myself@mail.com"
          type="email"
          icon="email"
          v-model.trim="mail"
          :disabled="user"
        />
      </b-field>
    </section>
  </SlideFrame>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "IdentificationStep",
  data() {
    return {
      loading: false,
      mail: "",
      error: "",
    };
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
  },
  mounted() {
    if (this.user) {
      this.mail = this.user.email;
    }
  },
  methods: {
    next() {
      this.loading = true;

      axios
        .post("/user", { mail: this.mail })
        .then((response) => {
          if (response.data.success) {
            this.$store.dispatch("refreshUser").then(() => {
              this.$emit("next");
            });
          }
        })
        .catch((error) => {
          this.error = error.response.data.message;
        })
        .finally(() => (this.loading = false));
    },
    prev() {
      this.$emit("prev");
    },
  },
};
</script>
