import { render, staticRenderFns } from "./ReviewPage.vue?vue&type=template&id=18818bd6&scoped=true&"
import script from "./ReviewPage.vue?vue&type=script&lang=js&"
export * from "./ReviewPage.vue?vue&type=script&lang=js&"
import style0 from "./ReviewPage.vue?vue&type=style&index=0&id=18818bd6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18818bd6",
  null
  
)

export default component.exports