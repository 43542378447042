<template>
  <section class="hero is-info is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container minus">
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <Transition :name="transition" mode="out-in">
              <component :is="slides[step]" @next="next()" @prev="prev()" />
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WelcomeStep from "../slides/WelcomeStep.vue";
import IdentificationStep from "../slides/IdentificationStep.vue";
import IntroductionStep from "../slides/IntroductionStep.vue";
import ExecutionStep from "../slides/ExecutionStep.vue";
import SummaryStep from "../slides/SummaryStep.vue";

export default {
  name: "ExamPage",
  data() {
    return {
      transition: "slide-left",
      step: 0,
      slides: [
        WelcomeStep,
        IdentificationStep,
        IntroductionStep,
        ExecutionStep,
        SummaryStep,
      ],
    };
  },
  methods: {
    next() {
      if (this.slides.length - 1 <= this.step) {
        return;
      }
      this.transition = "slide-left";
      this.step++;
    },
    prev() {
      if (0 >= this.step) {
        return;
      }
      this.transition = "slide-right";
      this.step--;
    },
  },
};
</script>

<style scoped>
.minus {
  margin-top: -200px;
}
.hero {
  padding-top: 160px;
}
</style>
