<template>
  <div>
    <b-collapse class="card is-shadowless" animation="slide" :open="false">
      <template #trigger="props">
        <div
          class="card-header is-clickable is-radiusless has-background-light"
        >
          <p class="card-header-title">
            {{ catalog.name }}
          </p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content left-border is-radiusless">
        <table class="table is-narrow is-fullwidth">
          <thead>
            <tr>
              <th>Level</th>
              <th>Content</th>
              <th>Duration (s)</th>
              <th>Answer</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="question in catalog.questions"
              :key="'question_' + question.question_id"
            >
              <td>
                <span v-if="!edit[question.question_id]">
                  {{ levels.find((l) => l.level_id == question.level_id).name }}
                </span>
                <b-select
                  v-else
                  size="is-small"
                  expanded
                  v-model="question.level_id"
                >
                  <option
                    v-for="level in levels"
                    :key="'level_' + level.level_id"
                    :value="level.level_id"
                  >
                    {{ level.name }}
                  </option>
                </b-select>
              </td>
              <td>
                <span v-if="!edit[question.question_id]">
                  {{ question.content }}
                </span>
                <b-input
                  v-else
                  type="textarea"
                  size="is-small"
                  expanded
                  v-model.trim="question.content"
                />
              </td>
              <td>
                <span v-if="!edit[question.question_id]">
                  {{ question.duration }}
                </span>
                <b-input
                  v-else
                  type="number"
                  step="1"
                  size="is-small"
                  expanded
                  v-model="question.duration"
                />
              </td>
              <td>
                <span v-if="!edit[question.question_id]">
                  <pre class="p-1">{{ question.answer }}</pre>
                </span>
                <b-input
                  v-else
                  type="textarea"
                  size="is-small"
                  expanded
                  v-model="question.answer"
                />
              </td>
              <td>
                <b-button
                  v-if="!edit[question.question_id]"
                  size="is-small"
                  type="is-info"
                  @click="editQuestion(question)"
                >
                  Edit
                </b-button>
                <b-button
                  v-else
                  size="is-small"
                  type="is-success"
                  @click="saveQuestion(question)"
                >
                  Save
                </b-button>
              </td>
            </tr>
            <tr v-if="addOpen">
              <td>
                <b-select size="is-small" expanded v-model="question.level_id">
                  <option
                    v-for="level in levels"
                    :key="'level_' + level.level_id"
                    :value="level.level_id"
                  >
                    {{ level.name }}
                  </option>
                </b-select>
              </td>
              <td>
                <b-input
                  type="textarea"
                  size="is-small"
                  expanded
                  v-model.trim="question.content"
                />
              </td>
              <td>
                <b-input
                  type="number"
                  step="1"
                  size="is-small"
                  expanded
                  v-model="question.duration"
                />
              </td>
              <td>
                <b-input
                  type="textarea"
                  size="is-small"
                  expanded
                  v-model="question.answer"
                />
              </td>
              <td>
                <b-button
                  size="is-small"
                  type="is-primary"
                  @click="addQuestion"
                  :class="{ 'is-loading': loading }"
                  :disabled="loading"
                >
                  Add
                </b-button>
              </td>
            </tr>
            <tr v-if="!addOpen">
              <td colspan="5" class="has-text-centered">
                <b-button
                  size="is-small"
                  type="is-primary"
                  @click="addOpen = true"
                  >Add</b-button
                >
              </td>
            </tr>
            <tr v-if="question_error">
              <td colspan="5">
                <b-message type="is-danger" has-icon>
                  {{ question_error }}
                </b-message>
              </td>
            </tr>
          </tbody>
        </table>

        <CatalogsNode
          v-for="c in catalog.children"
          :key="'catalog_' + c.catalog_id"
          :catalog="c"
          :levels="levels"
          @refresh="$emit('refresh')"
        />

        <div class="box">
          <form>
            <b-field
              label="Add a new category"
              horizontal
              :message="category_error"
              :type="{ 'is-danger': category_error }"
            >
              <b-input
                placeholder="Category name..."
                type="text"
                size="is-small"
                v-model.trim="category"
              ></b-input>
              <p class="control">
                <b-button
                  class="button is-primary"
                  :class="{ 'is-loading': category_loading }"
                  :disabled="category_loading"
                  size="is-small"
                  @click="addCategory"
                >
                  Add
                </b-button>
              </p>
            </b-field>
          </form>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CatalogsNode",
  props: {
    catalog: {
      type: Object,
      default: () => {},
    },
    levels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      addOpen: false,
      loading: false,
      category_loading: false,
      category: "",
      category_error: "",
      edit: {},
      question: {},
      question_error: "",
    };
  },
  methods: {
    addCategory() {
      this.category_loading = true;
      this.category_error = "";
      axios
        .post(`/admin/catalog`, {
          parent_id: this.catalog.catalog_id,
          name: this.category,
        })
        .then(() => {
          this.category = "";
          this.$emit("refresh");
        })
        .catch((error) => {
          this.category_error =
            error.response.data.message || "An error occurred!";
        })
        .finally(() => {
          this.category_loading = false;
        });
    },
    addQuestion() {
      this.loading = true;
      this.question_error = "";
      axios
        .post(`/admin/question`, {
          catalog_id: this.catalog.catalog_id,
          ...this.question,
        })
        .then(() => {
          this.question = {};
          this.$emit("refresh");
        })
        .catch((error) => {
          this.question_error =
            error.response.data.message || "An error occurred!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editQuestion(question) {
      this.$set(this.edit, question.question_id, true);
    },
    saveQuestion(question) {
      this.loading = true;
      this.question_error = "";
      axios
        .post(`/admin/question/${question.question_id}`, {
          ...question,
        })
        .then(() => {
          this.$emit("refresh");
          this.$set(this.edit, question.question_id, false);
        })
        .catch((error) => {
          this.question_error =
            error.response.data.message || "An error occurred!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.left-border {
  border-left: 1px solid #aaa;
}
</style>
