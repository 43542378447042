<template>
  <div class="columns">
    <div class="column has-text-centered is-4">
      <img src="@/assets/certification.svg" />
    </div>
    <div class="column">
      <SlideFrame title="Welcome to the checkup!">
        <p class="subtitle is-3">
          This platform will help us to assess your skills in different domains.
        </p>
        <p class="subtitle is-3">
          Ensure you are comfortable and ready to spend some time answering the
          test.
        </p>
        <template slot="actions">
          <div class="has-text-centered">
            <b-button
              class="is-primary is-large"
              icon-right="arrow-right-bold"
              @click="next()"
            >
              Get started
            </b-button>
          </div>
        </template>
      </SlideFrame>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeStep",
  methods: {
    next() {
      this.$emit("next");
    },
  },
};
</script>
