<template>
  <div>
    <p v-if="title" class="title is-1 pb-6">
      {{ title }}
    </p>
    <slot></slot>

    <div class="mt-5">
      <slot name="actions">
        <div class="level">
          <div class="level-left">
            <b-button
              icon-left="arrow-left-bold"
              @click="prev()"
              :disabled="loading"
              :class="{ 'is-loading': loading }"
            >
              Back
            </b-button>
          </div>
          <div class="level-right">
            <b-button
              class="is-primary"
              icon-right="arrow-right-bold"
              @click="next()"
              :disabled="loading"
              :class="{ 'is-loading': loading }"
            >
              Next
            </b-button>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideFrame",
  props: {
    title: String,
    loading: Boolean,
  },
  methods: {
    next() {
      this.$emit("next");
    },
    prev() {
      this.$emit("prev");
    },
  },
};
</script>

<style scoped></style>
