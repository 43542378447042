import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    admin: false,
    exams: [],
    selectedExam: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getExams(state) {
      return state.exams;
    },
    getCurrentExam(state) {
      if (
        state.selectedExam === null ||
        state.selectedExam >= state.exams.length
      )
        return null;
      return state.exams[state.selectedExam];
    },
    getAdmin(state) {
      return state.admin;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setExams(state, exams) {
      state.exams = exams;
      state.selectedExam = 0;
    },
    selectExam(state, index) {
      state.selectedExam = index;
    },
    setAdmin(state, admin) {
      state.admin = admin;
    },
  },
  actions: {
    refreshUser({ commit }) {
      return axios.get("/user").then((response) => {
        const user = response.data.user;
        const exams = response.data.exams;
        const admin = response.data.admin;

        commit("setUser", user);
        commit("setExams", exams);
        commit("setAdmin", admin);
      });
    },
  },
  modules: {},
});
