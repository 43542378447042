<template>
  <SlideFrame title="Hurra! You've completed the test!">

    <p class="subtitle is-3 has-text-centered">
        Congratulations!
    </p>
    <p class="subtitle is-3">
      You've completed the test, we will review your answers and get back to you soon!<br/>Check your mail in the coming few days.
    </p>

    <p class="subtitle is-3">
      <table v-if="summary" class="table is-fullwidth">
          <tbody>
              <tr>
                  <td>Questions</td>
                  <td><b>{{ summary.questions_count }}</b></td>
              </tr>
              <tr>
                  <td>Answers provided</td>
                  <td><b>{{ summary.answers_count }}</b></td>
              </tr>
              <tr>
                  <td>Completion</td>
                  <td><b>{{ Math.round(summary.answers_count / summary.questions_count * 100) }}%</b></td>
              </tr>
              <tr>
                  <td>Total duration</td>
                  <td><b>{{ moment.utc(moment(summary.completed_at).diff(summary.started_at)).format('H [hours] m [minutes] s [seconds]') }}</b></td>
              </tr>
          </tbody>
      </table>
    </p>

    <b-message v-if="error" type="is-danger" has-icon>
      {{ error }}
      <b-button @click="getSummary()">Retry</b-button>
    </b-message>

    <template slot="actions">
      <div class="has-text-centered">You can close this page.</div>
    </template>
  </SlideFrame>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "SummaryStep",
  data() {
    return {
      loading: false,
      error: null,
      summary: null,
      moment: moment,
    };
  },
  computed: {
    ...mapGetters({ exam: "getCurrentExam" }),
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      this.loading = true;
      this.error = null;
      axios
        .get(`/exam/${this.exam.exam_id}/summary`)
        .then((response) => {
          this.summary = response.data.summary;
        })
        .catch((error) => {
          this.error = error.response.data.message || "An error occurred!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
