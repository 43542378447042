<template>
  <SlideFrame>
    <div v-if="isBreak">
      <h1 class="title is-1">
        Next question starts in <b>{{ timeout }}</b> seconds...
      </h1>
      <b-message v-if="error" type="is-danger" has-icon>
        {{ error }}
        <b-button @click="pause()">Retry</b-button>
      </b-message>
    </div>
    <div v-if="!isBreak && question" class="question">
      <h2 class="subtitle is-3 has-text-centered">{{ timeout }}</h2>
      <b-progress :value="100 * (timeout / question.duration)"></b-progress>
      <b-progress
        type="is-success"
        size="is-medium"
        show-value
        :value="100 * (questionCount / exam.questions)"
        >Progress {{ questionCount }} / {{ exam.questions }}</b-progress
      >
      <p class="title is-4">{{ question.topic }} ({{ question.level }})</p>
      <p class="subtitle is-3">
        {{ question.content }}
      </p>
      <b-input
        ref="answer"
        v-model="answer"
        type="textarea"
        :focus="$refs.answer && $refs.answer.focus()"
        :disabled="loading || error"
      />
      <b-message v-if="error" type="is-danger" has-icon>
        {{ error }}
        <b-button @click="submit()">Retry</b-button>
      </b-message>
    </div>
    <template slot="actions">
      <div v-if="isBreak">
        <b-button
          v-if="!error"
          class="is-primary"
          :loading="loading"
          @click="skipPause()"
          >Don't wait</b-button
        >
      </div>
      <div v-if="!isBreak">
        <b-button
          v-if="!error"
          type="is-primary"
          expanded
          :loading="loading"
          @click="skipPause()"
          >Submit</b-button
        >
      </div>
    </template>
  </SlideFrame>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "ExecutionStep",
  data() {
    return {
      loading: false,
      error: "",
      isBreak: true,
      timeout: 0,
      question: null,
      answer: null,
      questionCount: 0,
    };
  },
  computed: {
    ...mapGetters({ exam: "getCurrentExam" }),
  },
  mounted() {
    const avoid = (e) => {
      e.preventDefault();
      return null;
    };

    document.addEventListener("copy", avoid);
    document.addEventListener("cut", avoid);
    document.addEventListener("paste", avoid);

    const body = document.body;
    body.oncopy = avoid;
    body.oncut = avoid;
    body.onpaste = avoid;

    this.pause();
  },
  methods: {
    run() {
      this.isBreak = false;

      this.waitFor(() => {
        this.submit();
      }, +this.question.duration);
    },
    submit() {
      this.loading = true;

      axios
        .post(
          `/exam/${this.exam.exam_id}/answer/${this.question.question_id}`,
          {
            answer: this.answer,
          }
        )
        .then((response) => {
          this.questionCount++;
          if (response.data.complete) {
            this.$emit("next");
            return;
          }
          this.pause();
        })
        .catch((error) => {
          this.error =
            (error.response && error.response.data.message) ||
            "An error occurred!";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pause() {
      this.error = null;
      this.answer = null;
      this.isBreak = true;
      this.waitFor(() => {
        this.loading = true;

        axios
          .get(`/exam/${this.exam.exam_id}/question`)
          .then((response) => {
            this.question = response.data.question;
            this.run();
          })
          .catch((error) => {
            this.error =
              (error.response && error.response.data.message) ||
              "An error occured!";
          })
          .finally(() => {
            this.loading = false;
          });
      }, +this.exam.pause);
    },
    skipPause() {
      this.timeout = 0;
    },
    waitFor(callback, duration) {
      this.timeout = duration + 1;
      const timer = () => {
        if (this.timeout > 0) {
          this.timeout--;
        }
        if (this.timeout <= 0) {
          callback();
          return;
        }
        setTimeout(timer, 1000);
      };
      timer();
    },
  },
};
</script>

<style scoped>
.question {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
